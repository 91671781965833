.content{
    width: 100%;
    height: 80vh;
    background: black ;
    display: flex;
    justify-content: center;
}

.body{
    width : 90%;
    background: black ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.contacto-info{
    color:white;
    font-size: 40px;
}

.formulario-contacto{
    width: 100% !important;
    padding: 15px;
}

.formulario-imput{
    width: 50%;
    
}

#filled-basic-label{
    color:white !important;
    /* font-size: 20px !important; */
}

#standard-multiline-static-label{
    color:white !important;
    padding: 12px;
}
.login{
    width: 100%;
    padding: 0;
    height: 80vh;
    align-self: center;
   display: flex;
   justify-content: center;
   align-items: center;
}

.bodyLogin {
    width: 60% !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
}

.bodyLogin h1{
    color: white;
    padding: 8px;
}

.formInput{
    flex-direction: row !important;
    align-items: center !important;
    text-align: center;
    padding: 5px;
}

.labelImput{
    padding: 3px;
    width:  85px;
}


.formInput input{
    border-radius: 9px;
}

.itemButton{
    border-radius: 9px;
    width: 70px;
    padding: 5px;
}
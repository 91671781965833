.content {
  align-items: flex-start ;
  width: 100% ;
  height: 80vh ;
  background: black ;
  display: flex ;
  justify-content: center ;
  overflow: auto;
}
.body {
  width : 90% ;
  background: black ;
  display: flex ;
  flex-direction: column  ;
  justify-content: center ;
  text-align: center ;
  overflow: auto;
}

.celular-pedido {
  display: flex;
  flex-direction: row;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .celular-pedido {
      line-height: 1.4; 
      display: "flex"; 
      flex-direction: column;
      align-items: center;
    }
  }
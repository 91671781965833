#content{
    width: 100%;
    height: 80vh;
    margin-top: 30px;
    background: black;
    display: flex;
    justify-content: center;
    /* padding-top: 15px; */
    overflow: auto;
}

.body{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.mySwiper{
    width: 100%;
    height: 1000px
}

.swiper-slide img{
 object-fit: cover;
 width: 40%;
 height: 100%;
}

.swiper-slide div{
    width: 100%;
    height: 100%;
}

.text-slide{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    
    .mySwiper{
        width: 100%;
        height: 600px
    }
    .swiper-slide img{
        object-fit: cover;
        width: 100%;
        height: 100%
    };
  }
.content{
    width: 100%;
    height: 80vh;
    background: black;
    display: flex;
    justify-content: center;
    /* align-items: flex-start;
    overflow: auto; */
}

.body{
    width : 90%;
    background: black ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.header{
    display: flex;
    flex-direction: row;
    padding-left: 190px
}

.header button{
    margin-left: 14px
}

.celular-cliente {
    display: flex;
  flex-direction: row;
}
.celular-cliente button{
    margin: 5px 5px 0px 5px;
    
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .celular-cliente {
      line-height: 1.4; 
      display: "flex"; 
      flex-direction: row;
      align-items: center;
    }
    .celular-cliente button{
        margin: 5px;
    }
    .col-3 {
        width: 60% !important;
    }
}
.content{
    width: 100%;
    height: 80vh;
    background: black;
    display: flex;
    justify-content: center;
    /* align-items: flex-start;
    overflow: auto; */
}

.body{
    width : 90%;
    background: black ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.header{
    display: flex;
    flex-direction: row;
    /* padding-left: 0  */
}

.header button{
    margin-left: 14px
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .header {
      line-height: 1.4; 
      display: "flex"; 
      flex-direction: row;
      align-items: center;
      padding: 0px !important;
    }
    .col-2 {
        width: 44.666667% !important ;
    }
    
    .col-1 {
        width: 31.333333% !important;
    }
   
}
.navbar .container-fluid{
    width: 90%;
    padding: 0;
    height: 100%;
}

.navbar .mb-3 {
    background-color: black !important;
}

.navbar .mb-3 .nav-link{
    color: white;
}

.navbar{
    height: 10vh;
    
    
}

.navbar .navbar-brand {
    color: white;
}

.navbar .igm-nav{    
    width:150px;
    height:100%;
}

.navbar .igm-nav div{    
    width:150px;
    height:100%;
}

.navbar .igm-nav img{
    
  object-fit: contain;
  width:100%;
  height:100%;
}

.navbar .container-fluid a{
    height: 100%;
    font-size: 20px;
}

.navbar-toggler.collapsed{
    background-color:white;
}

.navbar-expand-sm .navbar-nav .nav-link{
    padding: 15px 40px !important;
}
.contenido{
    width: 100%;
    height: 80vh;
    background: black ;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.title-fag{
    width: 100%;
    height: 30px;
    color: white;
    text-align: center;
}
.title-fag:hover{
    color: aqua;
}

.content-letf-fag{
    width: 50%;
    padding: 10px;
}

.content-letf-fag p{
    padding: 15px;
}

.content-ringth-fag{
    width: 50%;
    padding: 10px;
}

.content-ringth-fag p{
    padding: 10px;
}